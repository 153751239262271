import  React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import ParallaxBanner from "../components/parallaxBanner" 
import NextPage from "../components/featuredProject" 
import Specialties from "../components/specialties"
import TextBanner from "../components/textBanner"  
import SEO from "../components/seo"
import { graphql } from "gatsby" 

class StoryPage extends React.Component {

	render() {
			var data = this.props.data.allDatoCmsExpertise.nodes[0];
			var next = this.props.data.allDatoCmsOurStory.nodes[0];
		
	    return (
		  <Layout internal={this.props.location.state?.internal}>
		   	<SEO title="Atlantis Property Group" /> 
		   	<Header />
		   	<ParallaxBanner title={data.heroTitle} image={data.heroImage} />
		   	<TextBanner content={data.textContent} title={data.textTitle} author={data.textSubtitle} />  
		  	<Specialties cards={data.specialty} /> 
		  	<NextPage image={next.heroImage} title={next.heroTitle} caption="Next Page" slug='story' /> 
		  </Layout>
		)
	} 
}

export default StoryPage

export const query = graphql`
  query ExpertiseQuery {
  	allDatoCmsExpertise {
	    nodes {
	      heroTitle
	      heroImage {
	      	gatsbyImageData
	      }
	      textSubtitle
      	textContent
     	 	textTitle
	      specialty {
	        title
	        icon {
	        	gatsbyImageData(placeholder: NONE)
	        }
	        caption
	      }
	    }
	  }
	  allDatoCmsOurStory {
	    nodes {
	      heroTitle
	      heroImage {
	      	gatsbyImageData
	      }
	    }
	  }
  }
`
