import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"
import {chunk} from 'lodash'


function Card ({title,caption,icon}) {
  return(
    <div className="specialty-card bg-slate p40 text-center text-white">
        <div className="flex justify-center align-top">
          <div className="caption max-300">
            {caption}
          </div>
        </div>
        
        <div className="max-90 m-max-60 ma w-100"> 
          <div className="icon ratio-1-1 pos-rel">
            <GatsbyImage className='icon--image bg-image contain' image={icon.gatsbyImageData} alt={title} />
          </div>
        </div>
        <div className="flex align-bottom justify-center">
          <p className="h3 sm m0">{title}</p> 
        </div>
      </div>
  )
}


const Specialties = ({cards}) => {


function calcClass (index){
  if(index % 2 === 0){
    return 'cards-left'
  }
  return 'cards-right'
}


  const rows =  chunk(cards,2).map((cardRows,index) =>{
    const rowClass = `${calcClass(index)} grid  grid-3 mt20`
    return (
      <div className={rowClass} key={index} >
        {cardRows.map(({title,caption,icon},cardIndex) =>{
          return <Card title={title} key={cardIndex} caption={caption} icon={icon}/>
        })}
      </div>
    )
  })

  return (
    <div className="specialties p20">
      <div className="max-1600 ma">
        {rows}
      </div>
      <Spacer /> 
    </div> 
  ) 
}
export default Specialties
